"use client";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { sendGTMEvent } from "@next/third-parties/google";
import { Grid, Typography } from "@mui/material";

const ProductContainer = styled(Grid)`
  width: 100% !important;
  @media only screen and (max-width: 1000px) {
    padding: 0rem !important;
    width: 100% !important;
  }
  @media only screen and (min-width: 1600px) {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  @media only screen and (min-width: 1900px) {
    padding-left: 21rem !important;
    padding-right: 21rem !important;
  }
`;

const ProductDeatil = styled(Grid)`
  padding-top: 1rem !important;
  @media only screen and (max-width: 1000px) {
    padding: 2rem;
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    padding: 0.75rem;
    padding-top: 0.8rem;
  }
`;

const Title = styled(Typography)`
  font-family: Inter;
  font-size: 25.4px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  white-space: nowrap;
  color: #121212;
  line-height: 1.6;
  letter-spacing: 0.025em;
  margin: 10px 0;
  margin-bottom: 20px;
  @media only screen and (max-width: 1000px) {
    text-align: center;
    font-size: 17px;
    margin: 0;
    margin-bottom: 10px;
    white-space: normal;
    word-break: break-word;
  }
`;

export default function Body({ data }) {
  const { title } = data;
  useEffect(() => {
    sendGTMEvent({ event: "PLP_view" });
  }, []);
  return (
    <ProductContainer container className="C006">
      <ProductDeatil item sm={12} className="C006-title">
        <Title variant="h1" className="C006-title">
          {title}
        </Title>
      </ProductDeatil>
    </ProductContainer>
  );
}
